
// Fonts
//page title

//fliesstext
//@import url('https://fonts.googleapis.com/css?family=Raleway:400,500');

@mixin font($font-family, $font-file) {
    @font-face {
      font-family: $font-family;
      src: url($font-file+'.eot');
      src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#aller') format('svg');
      font-weight: normal;
      font-style: normal;
    }
  }
  
@include font('Satisfy', 'public/fonts/satisfy/satisfy-v10-latin-regular');
@include font('Raleway-100', 'public/fonts/raleway-v14-latin/raleway-v14-latin-100');
@include font('Raleway-400', 'public/fonts/raleway-v14-latin/raleway-v14-latin-regular');
@include font('Raleway-500', 'public/fonts/raleway-v14-latin/raleway-v14-latin-500');
@include font('Raleway-600', 'public/fonts/raleway-v14-latin/raleway-v14-latin-600');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

strong { 
    font-family: 'Raleway-600', sans-serif;
}

html {
    height: 100%;
    box-sizing: border-box;
  }

body {
    position: relative;
    background-color: #fff;
    color: #636b6f;
    font-family: 'Raleway-400', sans-serif;
    min-height: 100%;
    margin: 0;
    padding-bottom: 12rem;
}
.btn-primary {
    color: #fff;
    background-color: #806caf;
    border-color: #7966a5;
    font-family: 'Raleway-400', sans-serif;
}
.btn-primary:hover {
    background-color: #937ace;
    border-color: #7966a5;
}
.btn-primary:focus {
    background-color: #937ace;
    border-color: #7966a5;
}
.btn-primary:active {
    background-color: #937ace;
    border-color: #7966a5;
}
.btn-primary:active:focus {
    background-color: #937ace;
    border-color: #7966a5;
}
.btn-primary:active:hover {
    background-color: #937ace;
    border-color: #7966a5;
}
.btn-primary:visited {
    background-color: #937ace;
    border-color: #7966a5;
}

p {
    text-align:justify;
}
li {
    text-align:justify;
}
a {
    color: #806caf;
}
a:hover {
    color: #806caf;;
    font-style: underline !important;
}
a:visited {
    color: #806caf;
}
a:active {
    color: #806caf;
}

h1 {
        font-family: 'Raleway-500', sans-serif;
}

h2 {
    color: #806caf;
    font-family: 'Raleway-500', sans-serif;
}
h3 {
    font-family: 'Raleway-500', sans-serif;
}

.anker_hover a:hover{

    text-decoration: none;
}


.full-height {
    height: 100vh;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    font-size: 84px;
}

.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-family: 'Raleway-600', sans-serif;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

.img-responsive {
    margin: 0 auto;
}

.post-text img {
    padding-top: 10px;
    padding-bottom: 15px;
}

/* Navbar Styles */
.navbar-default {
    font-family: 'Raleway-400', sans-serif;
    font-size: 1.17em;
    margin-bottom:0px;
    border-color: #fff;
    border-radius: 0px;
    color: #806caf !important;
    -webkit-box-shadow: 0px 10px 23px -21px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 23px -21px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 23px -21px rgba(0,0,0,0.75);
    margin-bottom: 2.5px;
}
.navbar-brand {
    font-family: 'Satisfy', cursive;
    font-size: 1.6em;
    color: #806caf !important;
}
/* Homepage Stlyes */
.header-picture-homepage {    
    position: relative;
    padding: 200px 0;
    /* background-color: #6f5499; */
    /*background-image: url('IMG_0289_Farbkorrektur2_small.jpg'); */
    background-image: url('/images/IMG_0938_bearbeitet_neu_1800x702.jpg');
    /* background-size: 2048px 400px; 
    sieht nicht gut aus bei mobile
    background-size: 100% 240%; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index:0;
}
#navbar-container {
    margin-bottom: 0px;
}
.header-picture-planning {    
    position: relative;
    padding: 200px 0;
    /* background-image: url('IMG_9917_corrected.jpg'); */
    background-image: url('/images/IMG_0567_bearbeitet_1800x907.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index:0;
}
.logo img {
    position:relative;
    display:block;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto ;
    width: 80px;
    height:80px;
    margin-top:-40px;
    z-index:5;
}
.page-title {
   font-family: 'Satisfy', cursive;
   text-align: center;
   font-size: 4em;
   color: #806caf;
}
.page-text {
    padding-top: 30px;
    font-family: 'Raleway-400', sans-serif;
    font-size:1.2em;
    line-height:160%;
}
.hr-homepage {
    margin-top: 50px;
    margin-bottom: 50px;
}
.line-trenner {
    background-color: #fff ;
    display: block;
    height: 32px;
    margin: -53px auto 0;
    width: 84px;
}
.glyphicon.glyphicon-map-marker {
    font-size: 50px;
    margin-top:-20px;
    margin-left:15px;
}
/* Planning Page Styles */
.glyphicon.glyphicon-calendar {
    font-size: 50px;
    margin-top:-20px;
    margin-left:19px;
}
.icon {
    width: 50px;
    margin-top:-20px;
    margin-left:19px;
}
.icon-hotel {
    width: 50px;
    margin-top:-20px;
    margin-left:17px;
    margin-bottom:10px;
}
.icon-pen {
    width: 30px;
    margin-top:-10px;
    margin-bottom:10px;
}
.icon-tag {
    width: 30px;
    margin-bottom:10px;
}
.glyphicon.glyphicon-hourglass {
    font-size: 50px;
    margin-top:-20px;
    margin-left:18px;
}
.glyphicon.glyphicon-bed {
    font-size: 50px;
    margin-top:-20px;
    margin-left:18px;
}
.planning-content h2 {
    text-align:center;
    font-size:2.6em;
}
.planning-paragraph {
    padding-bottom: 30px;
}
.boxes {
    margin-top: 30px;
}
.boxes img {
    width: 100%;
}
/* --- Planning_Topic Styles --- */

.hr-planning_content {
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 70px;
    margin-bottom: 100px;
    border-top: 3px solid #eee;
}
.line-trenner_plannint_content {
	background-color: #fff ;
    display: block;
    height: 90px;
    margin: -140px auto 0;
    width: 100px;
}
.schnoerkel1 {
	width:150px;
	display: block;
	margin-left:350px;
}
.schnoerkel2 {
	width:150px;
	display: block;
	margin-left: -140px;
}
.topic-pic-planning-content img {
	position:relative;
    display:block;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto ;
    width: 80px;
    height:80px;
    margin-top:-40px;
    z-index:5;
}
#headline {
	text-align: center;
	padding-bottom:5px;
}
.planning-content-post h2 {
	padding-top:10px;
	text-align:center;
    font-size: 2.6em;
    color: #806caf;
}


.topic-header-info ul {
	margin-left:0px;
	padding-left:0px;
    margin-top:30px;

}
.topic-header-info span {
	padding-right: 12px;
}
.topic-header-info ul li {
	font-family: 'Raleway-400', sans-serif;
    font-size:1.1em;
	display: inline;
	list-style-type: none;
	padding-right: 15px;
}
.breadcrumb {
	margin-top:15px;
	margin-bottom:15px;
	background-color:#fff;
	padding-left:0px;
	font-family: 'Raleway-400', sans-serif;
}
.marker {
    display: none;
}
/*
.planning-content-post img {
    width:600px;
	display: block;
	padding-top:15px;
    margin-left: auto;
    margin-right: auto
} */
.planning-content-post hr {
	margin-top:15px;
}
.post-text {
	padding-top: 15px;
    font-family: 'Raleway-400', sans-serif;
    font-size:1.0em;
    line-height:160%;
    margin: 0 auto;
}
/* --- ckeditor styles --- */
.right {
    float:right;
    padding-left:10px;
}
.left {
    float:left;
    padding-right: 20px;
    padding-top: 0px !important;
}
.thead-light {
    color: #675492;
}
.table {
    font-family: sans-serif;
    color:rgb(101,107,111);
}
.table p {
    text-align:left;
}
.small-column {
    text-align:left;
    width: 13%;
}
.row-top-buffer { margin-top:20px; }
.col-top-buffer { margin-top:20px; }


.comments li{
    font-family: 'Raleway-400', sans-serif;
    border-left: none;
    border-right: none;
    border-top: none;
    border-top: 1px solid #d3e0e9;
}
.reply {
    background-color:rgba(218,213,230,0.8);
    margin: 20px;
    padding: 20px;
    color:black;
    border-radius: 5px;
}
.vdp-datepicker {
    color: black;
    font-family: 'Raleway-400', sans-serif;
}

.footer {
    padding: 2.5rem 0;
    color: #999;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
    border-top: .05rem solid #e5e5e5;
  }
  .footer p {
    text-align: center;
  }
  .footer p:last-child {
    margin-bottom: 0;
  }

  .cookie-consent-style {
    width: 100%;
    height: auto;
    color: rgb(0, 0, 0);
    background-color: #e7e6e6;
    text-align: center;
    padding-top:5px;
  }
  .cookie-consent-style button {
      margin: 5px;
  }
/* datepicker style */
.vdp-datepicker__calendar .cell.selected{
    color:#fff;
    background: #806caf !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
    border: 1px solid #806caf !important;
}

blockquote {
    margin-left:20px;
    font-size: 1em;
    font-style: italic;
    border-left: 5px solid #f490b2;
}

.blog-roll-text {
    width:700px;
}

.comments {
    margin-bottom: 50px;
}
.container {
    margin-bottom:20px;
}

.start-btn a {
    color: #fff;

}
.start-btn a:visited {
    color: #fff;
    text-decoration: none;
    
} 
.start-btn a:hover {
    text-decoration: none;
} 

.text-nowrap {
    white-space: nowrap !important;
}


/* --- smartphone css --- */
/* -- ausblenden der Schnoerkellinien auf planning_topic seite */

// Small devices (landscape phones, 576px and up)
@media (max-width: 575px)  { 
    .schnoerkel1, .schnoerkel2 {
        display:none;
        }
    .col-top-buffer { margin-top:20px; }
    .post-text {
        width: auto;
    }
    .page-text {
        font-size: 1em;
    }
    .page-text ul li {
        text-align: left;
    }
    .comments {
        width: auto;
        margin-bottom: 90px;

    }
    .blog-roll-text {
        width: auto;
    }
}
@media (min-width: 576px) { 
    .schnoerkel1, .schnoerkel2 {
        display:none;
        }
    .col-top-buffer { margin-top:20px; }
    .post-text {
        width: auto;
    }
    .comments {
        width: auto;
        margin-bottom: 90px;
    }

    .blog-roll-image {
        height: 300px;
    }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .schnoerkel1, .schnoerkel2 {
        display:none;
        }
    .col-top-buffer { margin-top:20px; }
    .comments {
        width: 700px;
        margin: 0 auto;
        padding-left:0;
        padding-right:0;
        margin-bottom: 50px;
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .schnoerkel1, .schnoerkel2 {
        display:none;
        }
        .col-top-buffer { margin-top:0px; }
        .post-text {
            width: 700px;
        }
        .comments {
            width: 700px;
            margin: 0 auto;
        }
    .table {
        margin-top: 20px;
    }
 }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .schnoerkel1, .schnoerkel2 {
        display:block;
        }
    .col-top-buffer { margin-top:0px; }
    .post-text {
        width: 700px;
    }
    .comments {
        width: 700px;
        margin: 0 auto;
    }
    .container {
        width: 970px;
    }
    .table {
        margin-top: 20px;
    }
    .blog-roll-image {
        height: 300px;
    }
 }
